#nav-bar {
  position: fixed;
  display: flex;
  height: 48px;
  width: 100%;
  color: white;
  padding: 5px 10px;
  background: rgba(1, 1, 1, 0);
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  z-index: 6;
  transition: background 0.5s ease-in-out, color 1s ease-in-out 1s; }
  #nav-bar:hover, #nav-bar.scrolled {
    background: rgba(1, 1, 1, 0.85); }
    #nav-bar:hover #logo,
    #nav-bar:hover ul li a, #nav-bar.scrolled #logo,
    #nav-bar.scrolled ul li a {
      color: white; }
  @media screen and (max-width: 480px) {
    #nav-bar .container .withSidePadding {
      width: 100%;
      justify-content: space-between; } }

#mobile-menu {
  background: #000;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-image: url("/images/db1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  display: none;
  position: fixed; }
  #mobile-menu.open {
    z-index: 5;
    width: 100vw;
    min-height: 100vh;
    display: block; }
  #mobile-menu ul {
    margin-top: 0px;
    list-style-type: none;
    -webkit-padding-start: 0;
    border-top: solid 1px rgba(255, 255, 255, 0.2); }
    #mobile-menu ul li {
      font-size: 18pt;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
      #mobile-menu ul li a {
        text-decoration: none;
        color: white; }
        #mobile-menu ul li a:hover {
          color: #fff; }
  #mobile-menu #mobile-menu-header {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: flex-end; }
  #mobile-menu #close-icon {
    cursor: pointer; }
    #mobile-menu #close-icon svg path {
      stroke: white; }

#hamburger svg #iPhone-8 {
  stroke: #fff; }

#desktop-menu {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  padding: 0; }
  #desktop-menu li a {
    color: #fff;
    text-decoration: none;
    font-size: 10pt;
    font-weight: 300;
    letter-spacing: 0.5pt;
    padding: 20px; }
    #desktop-menu li a:hover {
      color: white; }
  #desktop-menu li:last-of-type a {
    padding-right: 0px; }
  @media screen and (max-width: 1023px) {
    #desktop-menu {
      display: none; } }
