body {
  padding: 0;
  margin: 0;
  background-color: #000;
  color: #fff;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'Sans serif'; }

h1 {
  text-align: center;
  font-size: 32pt;
  font-weight: 100;
  letter-spacing: 1.25pt;
  text-transform: uppercase; }

h2 {
  text-align: center;
  font-weight: 100;
  letter-spacing: 1.25pt;
  text-transform: uppercase; }

h3 {
  text-align: center;
  font-weight: 100;
  letter-spacing: 1.25pt;
  text-transform: uppercase; }

p,
li {
  font-size: 12pt;
  font-weight: 200;
  letter-spacing: 0.5pt;
  line-height: 1.6em; }

ul {
  padding-bottom: 30px; }

form {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em 8em 4em;
  border: solid 1px rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px); }
  form h1 {
    font-size: 24pt;
    font-weight: 100;
    letter-spacing: 1pt;
    margin-top: 0;
    text-transform: initial; }
  form h2 {
    text-transform: initial; }
  form .ok {
    width: 14em; }
  form .input-group label {
    display: none; }
  form input {
    border: solid 1px white;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    font-size: 10pt;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'Sans serif';
    margin: 7px 0px;
    letter-spacing: 0.5pt;
    font-weight: 300; }
  form select {
    border: solid 1px white;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    font-size: 12pt;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'Sans serif';
    margin: 7px 0px 14px; }
  form a {
    color: white;
    text-decoration: underline;
    letter-spacing: 0.5pt;
    font-weight: 200;
    font-size: 10pt;
    margin: 5px 0px 10px; }
  form button {
    background: none;
    width: 100%; }
  form label {
    cursor: pointer;
    margin: 10px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12pt;
    font-weight: 200;
    letter-spacing: 0.5pt; }
    form label a {
      color: #fff;
      text-decoration: none; }
    form label span {
      font-size: 10pt;
      font-weight: 200;
      letter-spacing: 0.5pt; }
  form input[type='checkbox'] {
    width: auto;
    margin-right: 10px; }

.content {
  z-index: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.page {
  position: relative;
  z-index: 4; }

html {
  scroll-behavior: smooth; }

a.button.gold {
  font-weight: normal;
  text-transform: uppercase;
  background-color: #ffcc00;
  color: rgba(0, 0, 0, 0.8);
  padding: 12px 24px; }
  a.button.gold:hover {
    background-color: #fddb53; }
