#dashboardPage {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; }
  #dashboardPage .withSidePadding {
    width: 100%; }
  #dashboardPage h1 {
    text-align: left;
    margin-top: 72px;
    font-size: 32pt;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: capitalize; }

#videos {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr; }
  #videos .video {
    width: 296px;
    height: 204px;
    padding: 8px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease-in-out;
    cursor: pointer; }
    #videos .video:hover {
      background: rgba(255, 255, 255, 0.2); }
    #videos .video img.thumbnail {
      position: relative;
      width: 280px;
      min-height: 160px;
      border-radius: 4px;
      background: black;
      border: none; }
    #videos .video .details {
      padding-top: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      #videos .video .details svg {
        margin-right: 4px; }
  @media screen and (max-width: 768px) {
    #videos {
      grid-template-columns: 100%; }
      #videos .video {
        width: auto;
        height: auto; }
        #videos .video img.thumbnail {
          width: 100%;
          min-height: none;
          height: auto; } }
