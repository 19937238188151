#who-is-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media (max-width: 480px) {
    #who-is-section {
      flex-direction: column; } }
  #who-is-section .text-content {
    width: 50%; }
    #who-is-section .text-content .col-3 {
      padding: 3em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
    #who-is-section .text-content a {
      color: white; }
    @media (max-width: 480px) {
      #who-is-section .text-content {
        width: 100%; } }
  #who-is-section .image-content {
    width: 50%; }
    #who-is-section .image-content img {
      width: 100%; }
    @media (max-width: 480px) {
      #who-is-section .image-content {
        width: 100%; } }
