#hero {
  display: grid;
  flex-direction: column;
  align-content: end;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 4; }
  #hero p {
    font-size: 32pt;
    font-weight: 100;
    letter-spacing: 3pt;
    text-transform: uppercase; }
  #hero p {
    width: 60%;
    text-align: end; }
  @media screen and (max-width: 768px) {
    #hero p,
    #hero a.button {
      display: none; } }
  #hero #main-content {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 1024px) {
      #hero #main-content {
        position: absolute;
        right: 20px;
        top: 40vh;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start; } }
    #hero #main-content a {
      display: flex;
      width: intrinsic;
      text-decoration: none; }
