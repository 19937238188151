#logo {
  cursor: pointer;
  font-weight: 200;
  text-transform: uppercase;
  color: #fff;
  font-size: 14pt;
  letter-spacing: 4px; }
  @media screen and (min-width: 768px) {
    #logo .mobile {
      display: none; } }
  #logo .full {
    display: none; }
    @media screen and (min-width: 768px) {
      #logo .full {
        display: flex; } }
