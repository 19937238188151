#songs {
  width: 100%;
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 8px; }
  #songs th,
  #songs td {
    text-align: left;
    padding: 5px; }
  #songs tbody tr {
    transition: background 0.3s ease-in-out; }
    #songs tbody tr:hover {
      background: rgba(255, 255, 255, 0.05); }
    #songs tbody tr td {
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);
      cursor: pointer; }
      #songs tbody tr td:last-of-type {
        text-align: right; }
      #songs tbody tr td:first-of-type {
        width: 24px; }
      #songs tbody tr td a {
        color: white; }
  #songs tbody tr:last-of-type td {
    border-bottom: none; }
  #songs thead tr th:last-of-type {
    text-align: right; }
