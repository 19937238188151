#recordingPage {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto; }
  #recordingPage h1 {
    margin-top: 60px; }
  #recordingPage #recording {
    max-width: 100%;
    border: solid 1px rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    justify-content: center; }
    #recordingPage #recording img.thumbnail {
      max-width: 100%; }
    #recordingPage #recording img.playButton {
      position: absolute;
      width: 40px; }
  #recordingPage #recording-options {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: space-between; }
    #recordingPage #recording-options a.button {
      margin: 5px; }
      #recordingPage #recording-options a.button.disabled {
        opacity: 0.35; }
