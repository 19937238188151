@keyframes slideup {
  from {
    margin-top: 100%; }
  to {
    margin-top: 0%; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#video-player-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  top: 0px;
  z-index: 10;
  -webkit-backdrop-filter: blur(4px);
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-name: slideup; }

#exit-video,
#download-video {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 20px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.8);
  border: solid 1px rgba(255, 255, 255, 0.8);
  opacity: 0;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-name: fadein;
  transition: all 0.3s linear; }
  #exit-video:hover,
  #download-video:hover {
    transition: all 0.3s linear;
    color: rgba(0, 0, 0, 0.8);
    background: rgba(255, 255, 255, 0.8);
    border: solid 1px rgba(255, 255, 255, 0.65); }

#download-video {
  text-decoration: none;
  width: 100px;
  right: 90px; }

#video-player {
  opacity: 0;
  z-index: 11;
  width: 80vw;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-name: fadein; }
  #video-player video {
    background: black;
    border: solid 1px rgba(255, 255, 255, 0.15);
    width: 100%;
    border-radius: 8px; }
