#down-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px; }
  #down-arrow circle {
    fill: transparent;
    transition: all 0.5s; }
  #down-arrow polyline {
    stroke: #ffffff99;
    transition: all 0.5s; }
  #down-arrow:hover circle {
    fill: rgba(255, 255, 255, 0.8); }
  #down-arrow:hover polyline {
    stroke: #333; }
