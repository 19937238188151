@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#audio-player {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px;
  height: 100px;
  z-index: 3;
  background: rgba(0, 0, 0, 0.85);
  display: grid;
  grid-template-columns: repeat(5, 20%);
  align-items: center;
  letter-spacing: normal;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-name: fadein; }
  @media screen and (max-width: 480px) {
    #audio-player {
      grid-template-columns: repeat(3, 33%); } }
  #audio-player #artist-and-track {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    #audio-player #artist-and-track img {
      border: solid 1px #333333;
      border-radius: 4px;
      margin-right: 16px; }
    #audio-player #artist-and-track #description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center; }
      #audio-player #artist-and-track #description #track-name {
        font-size: 14pt;
        font-weight: bold; }
      #audio-player #artist-and-track #description #artist-name {
        font-size: 10pt;
        font-weight: normal; }
    @media screen and (max-width: 480px) {
      #audio-player #artist-and-track {
        display: none; } }
  #audio-player #time-track-length-display {
    display: flex;
    align-items: center; }
    #audio-player #time-track-length-display #current-time {
      font-size: 10pt;
      padding: 8px; }
    #audio-player #time-track-length-display #time-bar {
      position: relative;
      width: 200px; }
      #audio-player #time-track-length-display #time-bar #time-bar-full-length {
        position: absolute;
        height: 4px;
        width: 100%;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.2);
        cursor: pointer; }
      #audio-player #time-track-length-display #time-bar #time-bar-progress-length {
        position: absolute;
        height: 4px;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.4);
        cursor: pointer; }
    #audio-player #time-track-length-display #time-remaining {
      font-size: 10pt;
      padding: 8px; }
  #audio-player #audio-controls {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center; }
  #audio-player #volume-controls {
    display: flex;
    align-items: center;
    justify-content: center; }
    #audio-player #volume-controls #volume-bar {
      position: relative;
      width: 200px;
      margin: 0px 8px;
      padding: 2px 0px; }
      #audio-player #volume-controls #volume-bar #volume-bar-full-length {
        position: absolute;
        height: 4px;
        width: 100%;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.2);
        cursor: pointer; }
      #audio-player #volume-controls #volume-bar #volume-bar-current-level {
        position: absolute;
        width: 50%;
        height: 4px;
        border-radius: 2px;
        background: #b4b4b4;
        transition: width 0.1s linear;
        cursor: pointer; }
      #audio-player #volume-controls #volume-bar #volume-bar-handle {
        position: absolute;
        margin-left: calc(50% - 6px);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
        width: 12px;
        height: 12px;
        margin-top: -4px;
        border-radius: 6px;
        background: #b4b4b4; }
