#what-is-product-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; }
  #what-is-product-section a.button#join {
    font-size: 14pt;
    font-weight: normal;
    text-transform: uppercase;
    background-color: #ffcc00;
    color: rgba(0, 0, 0, 0.8);
    padding: 12px 24px; }
    #what-is-product-section a.button#join:hover {
      background-color: #fddb53; }
