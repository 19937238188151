#enter-the-club {
  margin-top: 50px; }
  #enter-the-club #plans {
    border: solid 1px rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
    background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px); }
    @media screen and (max-width: 768px) {
      #enter-the-club #plans {
        flex-direction: column; } }
  #enter-the-club #saxpack-plan {
    border: solid 1px rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 10px;
    padding-bottom: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px); }
  #enter-the-club .plan {
    width: 33%;
    text-align: center;
    padding-bottom: 20px; }
    #enter-the-club .plan:first-of-type {
      border-right: solid 1px rgba(255, 255, 255, 0.4); }
    #enter-the-club .plan:last-of-type {
      border-left: solid 1px rgba(255, 255, 255, 0.4); }
    @media screen and (max-width: 768px) {
      #enter-the-club .plan {
        width: 100%; }
        #enter-the-club .plan:first-of-type {
          padding-bottom: 30px;
          border-right: none;
          border-bottom: solid 1px rgba(255, 255, 255, 0.4); }
        #enter-the-club .plan:last-of-type {
          margin-top: 10px;
          border-left: none;
          border-top: solid 1px rgba(255, 255, 255, 0.4); } }
  #enter-the-club .price-display {
    text-align: center;
    color: #fff;
    font-weight: 100;
    margin-bottom: 30px; }
    #enter-the-club .price-display .price {
      padding: 3px;
      font-size: 24pt; }
  #enter-the-club ul {
    min-height: 250px;
    list-style-type: none;
    -webkit-padding-start: 0; }
    @media screen and (max-width: 768px) {
      #enter-the-club ul {
        min-height: 150px; } }
  #enter-the-club li {
    font-size: 10pt;
    color: #fff;
    line-height: 3em; }
    #enter-the-club li.not-available {
      text-decoration: line-through; }
  #enter-the-club p#extra-details {
    padding: 30px 0px 20px;
    font-size: 14pt; }
