a.button,
.button,
button {
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 10pt;
  letter-spacing: 0.5pt;
  border: solid 1px rgba(255, 255, 255, 0.4);
  transition: all 0.5s; }
  a.button:hover,
  .button:hover,
  button:hover {
    background: rgba(255, 255, 255, 0.15);
    border: solid 1px rgba(255, 255, 255, 0.15); }
