#signup #plans {
  width: 100%; }

#signup .plan {
  border-top: solid 1px #fff;
  text-align: center;
  margin-bottom: 60px; }

#signup .price-display {
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: 30px; }
  #signup .price-display .price {
    padding: 3px;
    font-size: 24pt; }

#signup ul {
  min-height: 200px;
  list-style-type: none;
  -webkit-padding-start: 0; }

#signup li {
  text-transform: uppercase;
  font-size: 11pt;
  color: #fff;
  line-height: 3em; }
