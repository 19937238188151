#footer {
  border-top: solid 1px rgba(255, 255, 255, 0.5);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10pt;
  color: #fff;
  font-weight: 200;
  letter-spacing: 0.75pt;
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(4px); }
  #footer .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between; }
    #footer .content a {
      color: white;
      text-decoration: none; }
